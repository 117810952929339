import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import {
  MailIcon,
  AtSymbolIcon,
  PhoneIcon,
  GlobeIcon,
  CalendarIcon,
} from "@heroicons/react/outline";

import { encode } from "js-base64";

import Header from "../components/headers/header";
import Ariane from "../components/ariane";
import Footer from "../components/footers/footer";
import FormExpert from "../components/forms/formExpertFiche";

// var image_profil = "image_temp.png"

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        reference,
        slug,
        title,
        template,
        modele,
        title_meta,
        description_meta,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
        raisonsocial,
        adresse,
        cp,
        ville,
        email,
        telephone,
        site,
        linkedin,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode(email);
  // const encryptedMail= encode("vintux@gmail.com");
  const moyen = "Formulaire Fiche Entreprise " + raisonsocial;

  var url_image_profil = "";
  if (embeddedImagesLocal === null || embeddedImagesLocal === undefined) {
    url_image_profil = "";
  } else {
    url_image_profil = embeddedImagesLocal[0];
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>
      <Ariane fil_ariane={fil_ariane} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow w-7xl border">
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="flex-shrink-0 mx-auto h-40 w-40 rounded-full">
                  {/* <GatsbyImage className="mx-auto h-40 w-40 rounded-full" alt={thumbnailText} image={getImage(embeddedImagesLocal[0])} /> */}
                  {/* <GatsbyImage className="mx-auto h-40 w-40 rounded-full" alt={thumbnailText} image={getImage(embeddedImagesLocal[0])} /> */}

                  {url_image_profil ? (
                    <GatsbyImage
                      className="mx-auto h-40 w-40 rounded-full"
                      alt={thumbnailText}
                      image={getImage(url_image_profil)}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
                    {raisonsocial}
                  </h1>
                  <p className="text-sm font-medium text-gray-600">
                    {adresse}
                    <br />
                    {cp} {ville}
                    <br />
                  </p>

                  {/* {praticien_email ? 
                      <p className="text-sm font-medium text-gray-600"><MailIcon className="inline-flex h-5 w-5 mr-2" />{praticien_email.substring(0,praticien_email.indexOf("@"))}<AtSymbolIcon className="inline-flex h-5 w-5" />{praticien_email.substring(praticien_email.indexOf("@")+1)}</p> 
                    : 
                      ''
                    } */}

                  {telephone ? (
                    <p className="text-sm font-medium text-gray-600">
                      <PhoneIcon className="inline-flex h-5 w-5 mr-2" />
                      {telephone}
                    </p>
                  ) : (
                    ""
                  )}

                  {site ? (
                    <p className="text-sm font-medium text-gray-600">
                      <GlobeIcon className="inline-flex h-5 w-5 mr-2" />
                      <a
                        href={site}
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-600 hover:text-gray-400"
                      >
                        {site}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  {/* {linkedin ? 
                      <p className="inline-flex text-sm font-medium text-gray-600 mt-2">
                        <a href={linkedin} target="_blank" rel="noreferrer" className="text-gray-600 hover:text-gray-400">
                          <LinkedinSquare className="h-6 w-6" />
                          LinkedIn
                        </a>
                      </p>
                    : 
                      ''
                    } */}
                  {/* {praticien_facebook ? 
                      <p className="inline-flex text-sm font-medium text-gray-600 mt-2">
                        <a href={praticien_facebook} target="_blank" rel="noreferrer" className="text-gray-600 hover:text-gray-400">
                          <FacebookSquare className="h-6 w-6" />
                        </a>
                      </p>
                    : 
                      ''
                    } */}
                </div>
                <div className="pt-4 block w-full border-transparent py-2 pl-3 pr-4 text-base center font-medium text-gray-600 hover:text-gray-800 lg:hidden">
                  <a
                    href="#rendez-vous"
                    className="inline-flex w-full items-center mr-6 rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100"
                  >
                    <CalendarIcon className="w-5 h-5 mr-2" /> Contactez-moi
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hidden lg:ml-6 lg:flex lg:space-x-8 h-16">
              <a
                href="#presentation"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-900"
              >
                Présentation
              </a>
              <a
                href="#discipline"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Discipline
              </a>
              <a
                href="#seance"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Séances
              </a>
              <a
                href="#formation"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Formation
              </a>
              <a
                href="#specialite"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Spécialité
              </a>
              <a
                href="#autres"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Autres informations
              </a>
            </div> */}
        </div>
      </div>

      <div className="relative max-w-7xl mx-auto h-full lg:flex">
        <div className="flex flex-1 overflow-hidden pb-4">
          <div className="flex flex-1 flex-col">
            <div className="mt-8 max-w-4xl px-4 sm:px-6 lg:px-8 prose">
              <MDXProvider>
                <MDXRenderer
                  remoteImages={embeddedImagesRemote}
                  localImages={embeddedImagesLocal}
                >
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </div>

        <aside
          id="rendez-vous"
          className="self-start top-32 col-span-1 px-8 lg:py-16 lg:w-1/3"
        >
          <div className="flex flex-col flex-grow rounded-lg text-center">
            <h3 className="text-2xl font-bold text-center">
              Demander votre devis
            </h3>
            <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
          </div>
        </aside>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        template
        modele
        title_meta
        description_meta
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        raisonsocial
        adresse
        cp
        ville
        email
        telephone
        site
        linkedin
      }
      body
    }
  }
`;

export default MdxPage;
